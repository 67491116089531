.input-field {
    display: inline-block;
    width: 100%;
    min-height: 56px;
    margin-bottom: 24px;
    padding: 24px 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #b3b3b3;
    border-radius: 20px;
    background-color: #fff;
    -webkit-transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease;
    transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease;
    color: #333;
    font-size: 16px;
  }

  .input-field.field-in-blue-row {
    margin-top: 11px;
    margin-bottom: 0px;
    padding-top: 28px;
    padding-left: 24px;
    border-style: none;
    background-color: transparent;
  }
  
  .input-field.field-in-blue-row.inline-label {
    padding-left: 106px;
  }

  .field-row-blue-label {
    position: absolute;
    left: 20px;
    top: 2px;
    right: auto;
    bottom: auto;
    font-size: 15px;
  }

  .div-block-27 {
    position: relative;
  }

  .div-block-26.border-right {
    margin-bottom: 0px;
    border-right: 1px solid #ddd;
  }

  .w-layout-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
  }

  .input-fields-grid {
    grid-column-gap: 24px;
    grid-row-gap: 0px;
  }

  .input-fields-grid.combined-feild-row-blue {
    position: relative;
    display: -ms-grid;
    display: grid;
    margin-bottom: 20px;
    padding-top: 8px;
    padding-left: 6px;
    grid-auto-columns: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border-radius: 40px;
    background-color: #f0f6ff;
    box-shadow: inset 3px 3px 40px -20px rgba(41, 116, 160, 0.13);
  }

  .modal-dialog .loqate-dropdowns ul {top: 30px;left:0; z-index: 2000;}