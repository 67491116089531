prog-approve {
    color: green !important;
}

prog-pending {
    color: yellow !important;
}

.text-limit {
    display: block;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }