.questions-radio {
    left: 17%;
    position: relative;
}

.device-qty {
    left: 17%;
    position: relative;
}

#btn-regular {
    height: 38px;
}

#saved-location {
    justify-content: right;
}