
#tracking .filters-card{
    background-color: #f0f6ff;
    }
    
#tracking .input-field.field-in-blue-row {
    padding-top: 18px;
}

#tracking .w-select > div:first-child {
    background-color: transparent;
}

#tracking .popup-form-bg {
    width: auto;
    margin: 0;
    overflow: visible;
}

#tracking .request-packaging-shipping-label {
    margin-top: 0;
}

#tracking .modal-content {
    border-radius: 1rem;
}

#tracking .btn-close {
    border: 1px solid black;
    border-radius: 50%;
}

#tracking .transparent-inner-icon {
    margin-left: 1em;
}

#tracking .transparent-inner-icon .icomoon {
    padding-right: 0.5em;
}
#tracking .modal-header {
    border-bottom: none;
    padding-bottom: 0;
}

#tracking td, th {
    white-space: nowrap;
}

#tracking table a {
    color: inherit;
    text-decoration: underline;
}

#tracking table.table button {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    font-weight: 500;
    color: #20a3f0;
    padding: 0;
}

.tracking_details .modal-header {
    background-color: transparent;
    height: 50px;
    padding: 0;
}

.tracking_details .modal-header .btn-close {
    top: 4rem;
    right: 1.5rem;
    z-index: 1;
}

.tracking_details .modal-content {
    box-shadow: none;
    background-color: transparent;
}

.tracking_details .card {
    border-radius: 40px;
}
.tracking_details_map  {
    position: relative;
    height: 300px;
    width: 100%;
}

.tracking_details .white-div, .tracking_details .white-div.last {
    height: auto;
}

.tracking_details .white-div p {
    transition: max-height .5s ease-out;
    overflow: hidden;
    max-height: 0;
}

.tracking_details .white-div p.open {
    max-height: 500px;
    transition: max-height .5s ease-in;
}

.tracking_details .white-div .form-heading {
    cursor: pointer;
}

@media (min-width: 992px) {
    .tracking_details .modal-lg {
        max-width: 992px;
    }
}

.tracking_details .device_list {
    background-color: white;
    height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
}

.tracking_details .device_list table {
    width: 100%;
    margin: 1rem;
}

.tracking_details .device_list tr td {
    font-weight: 400;
    padding-bottom: .5rem;
}