.modal-content .popup-form-bg-2 {
    width: auto;
    margin: 0;
    overflow: auto;
}

.request-packaging-shipping-label {
    margin-top: 0;
}

#audit td, th {
    white-space: nowrap;
}

#audit .transparent-inner-icon {
    cursor: pointer;
}

#audit td .icomoon {
    font-size: 30px;
}

#audit td a {
    color: inherit;
    text-decoration: underline;
}

.column_visibility {
    column-count: 3;
}

.column_visibility > div {
    margin-left: 3rem;
}

.cod_container .card, .modal .carousel {
    box-shadow: none;
}

#audit_container .table_info button {
    padding: 2px 22px 6px 22px;
    
}