.env-wrap{
    min-height: 300px;
    margin-top: 2rem;
    padding: 20px;
    padding-bottom: 26px;
    border-radius: 10px;
    text-align: center;
    background-image: linear-gradient(150deg, #ffffff, #E9F0FE);
}

.env-wrap .ico {
    font-family: 'icomoon';
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
}

.env-wrap .ico:before {
    color: #3becda;
}

.env-wrap .icon-MoblyzIcons_LightBulbCheck:before {
    content: "\e919";
}

.env-wrap .icon-MoblyzIcons_PigBank:before {
    content: "\e947";
}

.env-wrap .icon-co2:before {
    content: "\e92e";
}

.env-wrap .icon-trash:before {
    content: "\e950";
}

.env-wrap .icon-water:before {
    content: "\e908";
}

.env-wrap .icon-smog:before {
    content: "\e94f";
}

.env-wrap .icon-hazard:before {
    content: "\e94d";
}

.env-wrap .icon-recycle:before {
    content: "\e94e";
}

.env-wrap .icon-MoblyzIcons_Paid:before {
    content: "\e91d";
}

.text-color{
    color: #2fe492;
}

.header-wrap{
    width: 100%;
    overflow: hidden;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
}

.env-container{
    padding: 30px;
}