.device-image{
    position: relative;
}
.mini-column{
    top: 0px
}

.grade-col{
    height: 600px;
}

.grading-criteria{
    padding: 30px;
}