.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

/* .w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
} */

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

body, #root {
  background-color: transparent;
  font-family: 'Cera pro', sans-serif;
  color: #6d6d6d;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  height: 100%;
}

h1 {
  margin-top: 0px;
  margin-bottom: 16px;
  font-family: 'Cera pro', sans-serif;
  color: #0c2652;
  font-size: 56px;
  line-height: 130%;
  font-weight: 700;
  letter-spacing: normal;
}

h2 {
  margin-top: 24px;
  margin-bottom: 8px;
  font-family: 'Cera pro', sans-serif;
  color: #0c2652;
  font-size: 48px;
  line-height: 130%;
  font-weight: 700;
  letter-spacing: -0.75px;
}

h3 {
  margin-top: 16px;
  margin-bottom: 8px;
  font-family: 'Cera pro', sans-serif;
  color: #0c2652;
  font-size: 40px;
  line-height: 130%;
  font-weight: 700;
}

h4 {
  margin-top: 16px;
  margin-bottom: 8px;
  font-family: 'Cera pro', sans-serif;
  color: #0c2652;
  font-size: 32px;
  line-height: 130%;
  font-weight: 700;
}

h5 {
  margin-top: 16px;
  margin-bottom: 8px;
  font-family: 'Cera pro', sans-serif;
  color: #0c2652;
  font-size: 24px;
  line-height: 140%;
  font-weight: 700;
}

h6 {
  margin-top: 16px;
  margin-bottom: 8px;
  font-family: 'Cera pro', sans-serif;
  color: #0c2652;
  font-size: 16px;
  line-height: 140%;
  font-weight: 700;
}

p {
  margin-bottom: 24px;
}

a {
  display: inline-block;
  padding-right: 2px;
  padding-left: 2px;
  border-bottom: 1px solid transparent;
  -webkit-transition: color 400ms ease;
  transition: color 400ms ease;
  color: #20a3f0;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  border-bottom: 1px solid #20a3f0;
}

ul {
  margin-top: 0px;
  margin-bottom: 24px;
  padding-left: 32px;
}

ol {
  margin-top: 0px;
  margin-bottom: 24px;
  padding-left: 40px;
}

li {
  margin-bottom: 8px;
}

img {
  display: inline-block;
  max-width: 100%;
  border-radius: 5px;
}

label {
  display: block;
  margin-bottom: 6px;
  color: #333;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: normal;
}

blockquote {
  margin-bottom: 24px;
  padding: 24px;
  border-left: 0px none #000;
  border-radius: 5px;
  background-color: #f0f6ff;
  font-family: 'Cera pro', sans-serif;
  color: #0c2652;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: normal;
}

.class {
  position: relative;
  z-index: 999;
  display: inline-block;
  width: auto;
  height: auto;
  margin: 64px;
  padding: 16px;
  cursor: pointer;
}

.test {
  width: auto;
  height: auto;
  margin-top: 2.5vw;
  margin-right: 2.5vw;
  cursor: pointer;
}

.nav-toggle-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.trigger {
  position: relative;
  z-index: 20;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 56px;
  height: 56px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #202020;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.whatsapp {
  position: absolute;
  z-index: 18;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 56px;
  height: 56px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #4e4e4e;
}

.email {
  position: absolute;
  z-index: 18;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 56px;
  height: 56px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #4e4e4e;
}

.mobile {
  position: absolute;
  z-index: 18;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 56px;
  height: 56px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background-color: #4e4e4e;
}

.source-info {
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.clone-button {
  min-height: 40px;
  padding: 12px 16px;
  border-radius: 4px;
  background-color: #313131;
  box-shadow: none;
  -webkit-transition: background-color 600ms cubic-bezier(.19, 1, .22, 1);
  transition: background-color 600ms cubic-bezier(.19, 1, .22, 1);
  font-size: 14px;
  line-height: 14px;
}

.clone-button:hover {
  background-color: #242424;
  box-shadow: none;
}

.accordion {
  width: 100%;
  border-bottom: 1px solid #0c2652;
  cursor: pointer;
}

.styleguide {
  position: relative;
  max-width: 960px;
  padding: 40px 16px;
}

.stylesection {
  position: relative;
  width: 100%;
  margin-top: 40px;
}

.stylesection.container-style {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f0f6ff;
}

.typography {
  position: relative;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.buttons {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  top: 0;
  right: 0px;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 16px 16px 16px 32px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: transparent;
  -webkit-backdrop-filter: blur(60px);
  backdrop-filter: blur(60px);
}

.navbar-link {
  margin-right: 32px;
  box-shadow: none;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #b3b3b3;
  font-size: 14px;
  text-decoration: none;
}

.navbar-link:hover {
  box-shadow: none;
  color: #fff;
}

.navbar-link.w--current {
  color: #fff;
  font-weight: 700;
}

.color-tiles {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.color-tile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 160px;
  padding: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px none #000;
  border-radius: 5px;
  background-color: #0c2652;
}

.color-primary{
  border-style: none;
  background-color: #3cceda;
}

.color-white {
  background-color: #fff;
  color: #0c2652;
}

.color-dark-blue {
  background-color: #0c2652;
  color: #0c2652;
}

.color-grey {
  background-color: #b3b3b3;
}

.color-gainsboro {
  background-color: #ddd;
}

.color-white-smoke{
  background-color: #f1f1f1;
}

.color-blue-brand {
  background-color: #20a3f0;
  color: #0c2652;
}

.color-black-paragraph {
  background-color: #333;
}

.color-purple-light-background {
  background-color: #f0f6ff;
  color: #0c2652;
}

.color-text-color {
  background-color: #6d6d6d;
  color: #0c2652;
}

.color-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: hsla(0, 0%, 100%, 0.64);
  font-size: 12px;
  line-height: 16px;
}

.color-info.dark {
  color: rgba(0, 0, 0, 0.64);
  text-transform: uppercase;
}

.color-name {
  margin-top: 0px;
  margin-bottom: 0px;
}

.color-name.black {
  color: #0c2652;
}

.color-name.white {
  color: #f0f6ff;
}

.style-item {
  display: -ms-grid;
  display: grid;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  border-top: 1px solid #0c2652;
}

.style-item.no-bottom-padding {
  padding-bottom: 0px;
}

.style-item.with-bg {
  background-color: #f0f6ff;
}

.style-item.singlerow {
  -ms-grid-columns: 3.5fr 0.25fr;
  grid-template-columns: 3.5fr 0.25fr;
}

.style-item.modal-bg {
  -ms-grid-columns: 0.25fr 1fr;
  grid-template-columns: 0.25fr 1fr;
  background-color: #ddd;
}

.typography-label {
  color: #b3b3b3;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: normal;
  text-transform: uppercase;
}

.typography-label.header {
  overflow: hidden;
}

.no-margin {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Cera pro', sans-serif;
  color: #333;
  font-weight: 300;
}

.header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.header-inner {
  width: 100%;
  max-width: 600px;
}

.header-lead {
  margin-bottom: 0px;
  font-family: Raleway, sans-serif;
}

.forms {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.lead {
  font-size: 18px;
  line-height: 32px;
}

.link {
  display: inline-block;
}

.link-2 {
  box-shadow: inset 0 -8px 0 0 #0c2652;
}

.input-field {
  display: inline-block;
  width: 100%;
  min-height: 56px;
  margin-bottom: 24px;
  padding: 24px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #b3b3b3;
  border-radius: 20px;
  background-color: #fff;
  -webkit-transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease;
  color: #333;
  font-size: 16px;
}

.input-field.country {
  padding-top: 0px;
  padding-bottom: 0px;
}

.input-field.search {
  margin-bottom: 0px;
  border-style: none;
  border-radius: 40px;
}

.input-field.field-in-blue-row {
  margin-top: 11px;
  margin-bottom: 0px;
  padding-top: 28px;
  padding-left: 24px;
  border-style: none;
  background-color: transparent;
}

.input-field.field-in-blue-row.inline-label {
  padding-left: 106px;
}

.text-area {
  display: inline-block;
  width: 100%;
  max-height: 400px;
  min-height: 160px;
  margin-bottom: 24px;
  padding: 16px 20px 24px;
  border-style: solid;
  border-width: 1px;
  border-color: #b3b3b3;
  border-radius: 20px;
  -webkit-transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease;
  font-size: 16px;
}

.text-area:focus {
  border-style: solid;
  border-color: #20a3f0;
  color: #0c2652;
}

.text-area::-webkit-input-placeholder {
  color: #8d8c8c;
}

.text-area:-ms-input-placeholder {
  color: #8d8c8c;
}

.text-area::-ms-input-placeholder {
  color: #8d8c8c;
}

.text-area::placeholder {
  color: #8d8c8c;
}

.select {
  width: 100%;
  min-height: 56px;
  margin-bottom: 24px;
  padding: 8px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #b3b3b3;
  border-radius: 20px;
  background-color: #fff;
  background-position: 96% 50%;
  background-size: 16px;
  background-repeat: no-repeat;
  -webkit-transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease, -webkit-transform 200ms cubic-bezier(.19, 1, .22, 1);
  transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease, -webkit-transform 200ms cubic-bezier(.19, 1, .22, 1);
  transition: transform 200ms cubic-bezier(.19, 1, .22, 1), color 200ms ease, background-color 200ms ease, border-color 200ms ease;
  transition: transform 200ms cubic-bezier(.19, 1, .22, 1), color 200ms ease, background-color 200ms ease, border-color 200ms ease, -webkit-transform 200ms cubic-bezier(.19, 1, .22, 1);
  color: #333;
  font-size: 16px;
  cursor: pointer;
}

/* .select {
  width: 100%;
  min-height: 56px;
  margin-bottom: 24px;
  padding: 8px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #b3b3b3;
  border-radius: 20px;
  background-color: #fff;
  background-image: url('../images/Chevron-down.svg');
  background-position: 96% 50%;
  background-size: 16px;
  background-repeat: no-repeat;
  -webkit-transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease, -webkit-transform 200ms cubic-bezier(.19, 1, .22, 1);
  transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease, -webkit-transform 200ms cubic-bezier(.19, 1, .22, 1);
  transition: transform 200ms cubic-bezier(.19, 1, .22, 1), color 200ms ease, background-color 200ms ease, border-color 200ms ease;
  transition: transform 200ms cubic-bezier(.19, 1, .22, 1), color 200ms ease, background-color 200ms ease, border-color 200ms ease, -webkit-transform 200ms cubic-bezier(.19, 1, .22, 1);
  color: #333;
  font-size: 16px;
  cursor: pointer;
} */

.select:focus {
  border-style: solid;
  color: #0c2652;
}

.select::-webkit-input-placeholder {
  color: #8d8c8c;
}

.select:-ms-input-placeholder {
  color: #8d8c8c;
}

.select::-ms-input-placeholder {
  color: #8d8c8c;
}

.select::placeholder {
  color: #8d8c8c;
}

.select._00 {
  margin-bottom: 0px;
}

.select._00.mini {
  min-height: 40px;
}

.select._00.mini.white {
  min-height: 20px;
  margin-right: 0px;
  padding: 10px 14px 0px 2px;
  border-style: none;
  background-color: #fff;
  background-position: 90% 50%;
  background-attachment: scroll;
  font-size: 14px;
  line-height: 14px;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.select._00.mini.white.rows-per-page {
  padding-top: 2px;
  padding-right: 10px;
  padding-left: 10px;
}

.select._00.mini.white.program-select {
  height: 44px;
  min-height: 24px;
  margin-top: 0px;
  padding-top: 18px;
  padding-bottom: 0px;
  padding-left: 14px;
  font-size: 15px;
}

.select.field-in-blue-row {
  min-height: 50px;
  margin-top: 13px;
  margin-bottom: 0px;
  padding-bottom: 5px;
  border-style: none;
  background-color: transparent;
}

.gradient {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.color-code {
  margin-right: 8px;
}

.radio-button-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-bottom: 16px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.radio-button {
  width: 16px;
  height: 16px;
  margin-top: 0px;
  margin-right: 12px;
  margin-left: 0px;
  padding: 12px;
  border-style: none;
  border-color: #7e7e7e;
  background-color: #ddd;
  -webkit-transition: opacity 200ms ease, border-color 200ms ease, background-color 200ms ease;
  transition: opacity 200ms ease, border-color 200ms ease, background-color 200ms ease;
  cursor: pointer;
}

.radio-button:hover {
  border-color: #3cceda;
}

/* .radio-button.w--redirected-checked {
  border-width: 1px;
  border-color: #0c2652;
  background-color: #0c2652;
  background-image: url('../images/radio-active.svg'), -webkit-gradient(linear, left top, left bottom, from(#3cceda), to(#3cceda));
  background-image: url('../images/radio-active.svg'), linear-gradient(180deg, #3cceda, #3cceda);
  background-position: 50% 50%, 0px 0px;
  background-size: 6px 6px, auto;
  background-repeat: no-repeat, repeat;
} */

.radio-button.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3cceda;
}

.radio-button.checked {
  width: 14px;
  height: 14px;
  margin-left: -4px;
  border-style: solid;
  border-width: 4px;
  border-color: #ddd;
  background-color: #3cceda;
}

.checkbox-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  padding-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkbox-field.password {
  margin-bottom: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.checkbox {
  width: 16px;
  height: 16px;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 0px;
  padding: 12px;
  border-style: none;
  border-color: #7e7e7e;
  border-radius: 5px;
  background-color: #ced8da;
  -webkit-transition: opacity 200ms ease, border-color 200ms ease, background-color 200ms ease;
  transition: opacity 200ms ease, border-color 200ms ease, background-color 200ms ease;
  cursor: pointer;
}

.checkbox:hover {
  border-color: #3cceda;
}

/* .checkbox.w--redirected-checked {
  border-style: none;
  border-width: 1px;
  border-color: #0c2652;
  border-radius: 7px;
  background-color: #0c2652;
  background-image: url('../images/check.svg'), -webkit-gradient(linear, left top, left bottom, from(#3cceda), to(#3cceda));
  background-image: url('../images/check.svg'), linear-gradient(180deg, #3cceda, #3cceda);
  background-position: 50% 50%, 0px 0px;
  background-size: 12px, auto;
  background-repeat: no-repeat, repeat;
} */

.checkbox.w--redirected-focus {
  box-shadow: none;
}

.checkbox.flip {
  margin-right: 0px;
  margin-left: 10px;
}

.checkbox.flip.password {
  margin-bottom: 6px;
  padding: 8px;
}

.primary-btn {
  position: relative;
  margin-top: -4px;
  padding: 15px 32px 16px 49px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  border-radius: 20px;
  background-color: #3cceda;
  box-shadow: none;
  -webkit-transition: color 400ms ease, -webkit-transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  transition: color 400ms ease, -webkit-transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  transition: color 400ms ease, transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  transition: color 400ms ease, transform 200ms cubic-bezier(.175, .885, .32, 1.275), -webkit-transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.primary-btn:hover {
  background-color: #fff;
  box-shadow: none;
  color: #3cceda;
}

.primary-btn:active {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.primary-btn:focus {
  box-shadow: 0 0 3px 1px rgba(0, 0, 255, 0.48);
}

.primary-btn.full {
  width: 100%;
}

.primary-btn.full.form-button {
  margin-top: 20px;
}

.primary-btn.full.form-button.save-settings {
  margin-top: 60px;
}

.secondary-button {
  display: inline-block;
  padding: 15px 32px 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #3cceda;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 17px 20px -9px rgba(12, 38, 82, 0.15);
  -webkit-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: border-color 300ms ease, color 400ms ease, -webkit-transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  transition: border-color 300ms ease, color 400ms ease, -webkit-transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  transition: border-color 300ms ease, color 400ms ease, transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  transition: border-color 300ms ease, color 400ms ease, transform 200ms cubic-bezier(.175, .885, .32, 1.275), -webkit-transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  color: #3cceda;
  font-weight: 500;
}

.secondary-button:hover {
  background-color: #f1f1f1;
  box-shadow: none;
}

.secondary-button:active {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.secondary-button:focus {
  box-shadow: 0 0 3px 1px rgba(0, 0, 255, 0.48);
}

.secondary-button.save-form {
  margin-bottom: -6px;
}

.secondary-button.save-form._2 {
  height: 59px;
  text-align: center;
}

.secondary-button.hidden {
  display: none;
}

.secondary-button.icomoon-withicon {
  padding-right: 18px;
  padding-left: 18px;
  font-size: 36px;
}

.container {
  width: 1200px;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.container.medium {
  width: 900px;
}

.container.small {
  width: 600px;
}

.container.super-small {
  width: 400px;
}

.container.inner-space {
  padding-top: 40px;
  padding-bottom: 40px;
}

.section {
  padding-top: 100px;
  padding-bottom: 100px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section.light-purple {
  background-color: #f0f6ff;
}

.section.light-purple.full {
  width: 100vw;
  height: 100vh;
}

.section.two-column-grid {
  display: -ms-grid;
  display: grid;
  padding-top: 0px;
  padding-bottom: 0px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section.no-padding {
  padding: 0px;
}

.section.no-padding.two-column-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 0.75fr 1fr;
  grid-template-columns: 0.75fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.left-div.light-purple {
  padding: 60px 40px 40px;
  background-color: #f0f6ff;
}

.left-div.light-purple.fixed {
  position: -webkit-sticky;
  position: sticky;
}

.right-div.light-purple {
  background-color: #f0f6ff;
}

.right-div.white {
  padding: 40px 0px;
  background-color: #fff;
}

.image-full {
  width: 100%;
  height: 100%;
}

.image-full.extra-up {
  margin-top: 70px;
}

.image-full.extra-up.logo-inner {
  display: block;
  width: 390px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
}

.image-full.extra-up.logo-inner {
  display: block;
  width: 390px;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
}

.image-full.extra-up.logo-inner.support-ticket {
  margin-top: 10px;
  margin-bottom: 10px;
}

.image-full.extra-up.logo-inner.support-ticket-copy {
  margin-top: 10px;
  margin-bottom: 10px;
}

.image-full.settings-svg {
  width: 230px;
  margin-top: 30px;
}

.image-full.edit-account {
  width: 230px;
  margin-top: 0px;
}

.logo {
  width: 176px;
}

.logo.link {
  border-bottom-style: none;
}

.logo.link.login {
  display: block;
  width: 200px;
  margin-right: auto;
  margin-left: auto;
  border-bottom-style: none;
}

.logo.link.dashboard {
  width: 150px;
}

.laptop-div {
  width: 90%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.heading-3.marjin-bottom-30 {
  margin-bottom: 30px;
}

.heading-2.marjin-bottom-30 {
  margin-bottom: 30px;
}

.heading-2.marjin-bottom-30.up-0 {
  margin-top: 0px;
}

.form-heading-div {
  margin-bottom: 30px;
  border-bottom: 1px solid #b3b3b3;
}

.form-heading-div.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.form-heading-div.flex.form {
  padding-bottom: 8px;
}

.form-heading-div.flex.form.absolute {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 1;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 40px;
  border-bottom-style: none;
  background-color: #fff;
}

.heading-5.black {
  color: #333;
}

.heading-5.device-value {
  margin-top: 0px;
  margin-bottom: 48px;
  font-weight: 400;
  text-align: center;
}

.input-fields-grid {
  grid-column-gap: 24px;
  grid-row-gap: 0px;
}

.input-fields-grid.single-row {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.input-fields-grid.one {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.input-fields-grid.answer-spacing {
  margin-top: 24px;
  margin-bottom: 0px;
}

.input-fields-grid.answer-spacing._1 {
  -ms-grid-columns: 1fr 1fr 0.5fr 1fr;
  grid-template-columns: 1fr 1fr 0.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.input-fields-grid.three {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.input-fields-grid.combined-feild-row-blue {
  position: relative;
  display: -ms-grid;
  display: grid;
  margin-bottom: 20px;
  padding-top: 8px;
  padding-left: 6px;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 40px;
  background-color: #f0f6ff;
  box-shadow: inset 3px 3px 40px -20px rgba(41, 116, 160, 0.13);
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.password.label {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
}

.select-way-lang {
  width: 100%;
  height: 50px;
  max-width: 500px;
  background-color: transparent;
}

.grid {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.three-column-input {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.form.registration {
  margin-bottom: 40px;
}

.form.registration._00 {
  margin-bottom: 0px;
}

.form.registration._00.device-inside {
  padding-right: 3%;
  padding-left: 3%;
}

.paragraph {
  display: inline-block;
}

.paragraph.right-10 {
  margin-right: 10px;
}

.paragraph.right-10.down-0 {
  margin-bottom: 0px;
}

.paragraph.b-10 {
  margin-bottom: 0px;
}

.paragraph._00 {
  width: auto;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
}

.paragraph._00.right-10 {
  margin-right: 10px;
}

.paragraph._00.down-32 {
  margin-bottom: 32px;
}

.paragraph._00.bold {
  font-weight: 700;
}

.paragraph._00.bold.down-6 {
  margin-bottom: 6px;
  color: #0c2652;
  font-weight: 500;
}

.paragraph._00.bold.down-6.left {
  color: #0c2652;
  font-weight: 500;
}

.paragraph._00.left {
  text-align: left;
}

.paragraph._00.left._14 {
  font-size: 14px;
  line-height: 20px;
}

.paragraph._00.records {
  margin-right: 10px;
  line-height: 20px;
}

.paragraph._00.signs {
  padding-bottom: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.paragraph._00.signs-copy {
  display: block;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 0px;
  border-top: 1px solid #b3b3b3;
}

.paragraph._00.device-info {
  margin-top: -50px;
}

.paragraph._00.signs {
  padding-bottom: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.paragraph._00.signs-copy {
  display: block;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 0px;
  border-top: 1px solid #b3b3b3;
}

.paragraph._00._12 {
  font-size: 14px;
  line-height: 14px;
}

.paragraph._00.header-p {
  width: 130px;
  margin-top: 5px;
  padding-left: 13px;
  line-height: 20px;
  text-align: right;
}

.paragraph._00.white-p {
  color: #fff;
}

.paragraph.white {
  color: #fff;
}

.paragraph.white._00 {
  padding-left: 0px;
  color: #b3b3b3;
  font-size: 15px;
  font-weight: 500;
}

.paragraph.up-24 {
  margin-top: 24px;
  font-weight: 300;
}

.paragraph.table {
  margin-bottom: 0px;
  font-size: 13px;
  line-height: 20px;
  white-space: nowrap;
}

.paragraph.grade {
  margin-top: 70px;
  margin-bottom: 0px;
}

.paragraph.block {
  display: block;
}

.paragraph.block.down-10 {
  margin-bottom: 10px;
}

.paragraph.block.down-20 {
  margin-bottom: 20px;
}

.paragraph.block.down-6 {
  margin-bottom: 6px;
}

.paragraph.block.down-6.inline {
  display: inline-block;
  padding-right: 6px;
}

.paragraph.block.down-0 {
  margin-bottom: 0px;
}

.paragraph.block.up-32 {
  margin-top: 32px;
  margin-bottom: 6px;
}

.paragraph.block.down-32 {
  margin-bottom: 32px;
}

.div-block-3 {
  margin-bottom: 6px;
}

.blue-link.right-10 {
  margin-right: 10px;
}

.login-div.light-purple {
  background-color: #f0f6ff;
}

.login-div.white {
  margin-top: 60px;
  padding: 40px;
  background-color: #fff;
}

.navbar-link-2 {
  margin-right: 32px;
  box-shadow: none;
  -webkit-transition: color 200ms ease;
  transition: color 200ms ease;
  color: #b3b3b3;
  font-size: 14px;
  text-decoration: none;
}

.navbar-link-2:hover {
  box-shadow: none;
  color: #fff;
}

.navbar-link-2.w--current {
  color: #fff;
  font-weight: 700;
}

.empty-space {
  height: 50vh;
}

.success-message {
  background-color: #f0f6ff;
}

.nav-link-icon {
  width: 15px;
  height: 15px;
  margin-right: 15px;
  line-height: 15px;
}

.left-navbar {
  margin-top: 40px;
}

.nav-list-item {
  margin-bottom: 20px;
}

.logo-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo-2 {
  width: 120px;
}

.nav-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-style: none;
  color: #181818;
  text-decoration: none;
}

.nav-link:hover {
  border-bottom-width: 0px;
  border-bottom-color: transparent;
  color: #3a96db;
}

.nav-link.w--current {
  color: #3a96db;
}

.nav-link-text {
  margin-bottom: 0px;
  color: #6d6d6d;
  font-size: 15px;
  line-height: 14px;
}

.left-sidebar {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100vh;
  margin-top: 0px;
  padding: 20px;
  border-top-right-radius: 30px;
  background-color: #fff;
}

.left-sidebar.sticky {
  position: -webkit-sticky;
  position: sticky;
  border-radius: 0px;
}

.body.light-purple {
  background-color: #f0f6ff;
}

.dashboard-grid {
  grid-column-gap: 41px;
  -ms-grid-columns: 1fr 0.5fr;
  grid-template-columns: 1fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.dashboard-grid.absolute {
  position: absolute;
  top: 0px;
}

.grid-2 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.numbers-grid {
  margin-top: 20px;
  margin-bottom: 64px;
  justify-items: center;
  grid-column-gap: 32px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.middle-div {
  border: 1px none #000;
}

.middle-div.hidden {
  display: none;
}

.graph-div {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  padding-top: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: #fff;
  border-radius: 24px;
  background-image: linear-gradient(135deg, #fff, rgba(183, 210, 234, 0.1));
  box-shadow: 0 18px 50px -20px rgba(54, 97, 183, 0.1);
  text-align: center;
}

.graph-div.last-div {
  margin-top: 160px;
}

.upper-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.upper-div.center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.upper-div.center.white {
  overflow: visible;
  width: 128%;
  background-color: #fff;
}

.upper-div.center.white.left {
  width: 106%;
  padding-left: 3%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.left-inner-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 64px;
  height: 64px;
  margin-top: -6px;
  margin-left: -6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 48px;
  background-image: radial-gradient(circle farthest-corner at 0% 0%, rgba(60, 206, 218, 0.5), #3cceda);
  box-shadow: 1px 13px 16px -7px rgba(85, 67, 184, 0.15);
}

.left-inner-icon.pink {
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(231, 134, 178, 0.7), #e786b2);
}

.left-inner-icon.green {
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(106, 231, 163, 0.8), #6ae7a3);
}

.left-inner-icon.blue {
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(32, 163, 240, 0.5), #20a3f0);
}

.heading-4 {
  color: #20425f;
  font-weight: 500;
}

.heading-4.up-0 {
  margin-top: 0px;
}

.heading-4.up-0.down--32 {
  margin-bottom: 32px;
}

.heading-4.up-8 {
  margin-top: -80px;
  font-size: 36px;
}

.heading-4.table {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}

.heading-4._00 {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 32px;
}

.heading-4.device-value-heading {
  margin-top: 0px;
  margin-bottom: 24px;
}

.heading-4.device-value {
  margin-top: -5px;
  margin-bottom: 0px;
  padding-top: 0px;
  color: #fff;
  font-size: 38px;
  line-height: 32px;
  font-weight: 400;
}

.help-grey-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 22px;
  height: 22px;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 48px;
  background-color: #b3b3b3;
  cursor: pointer;
}

.help-grey-icon.graph {
  width: 18px;
  height: 18px;
  margin-top: 6px;
  margin-right: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: #b3b3b3;
  background-color: #fff;
  color: #b3b3b3;
  font-size: 14px;
}

.help-grey-icon.graph.absolute {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 18px;
  height: 18px;
  background-color: #fff;
  font-size: 14px;
}

.help-grey-icon.right-10 {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #b3b3b3;
  background-color: #fff;
  font-size: 14px;
  line-height: 14px;
}

.help-grey-icon.table {
  margin-right: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #b3b3b3;
  background-color: #fff;
}

.help-grey-icon.table.absolute {
  position: absolute;
  left: auto;
  top: 22%;
  right: 2%;
  bottom: auto;
  width: 20px;
  height: 20px;
  margin-right: 0px;
  padding-top: 2px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  background-color: #fff;
  color: #b3b3b3;
  font-size: 14px;
}

.help-grey-icon.table.absolute.hiidden {
  opacity: 0;
}

.heading-content-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -20px;
  padding-right: 64px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-content-div.sp-it-away {
  margin-top: -56px;
  padding-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.heading-content-div.vertical {
  margin-bottom: 44px;
  padding-right: 0px;
  padding-left: 14px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.bottom-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.audit-report-button {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.audit-report-button.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.audit-report-button.button.new {
  height: 60px;
  padding: 10px 20px;
  border-top-left-radius: 20px;
  background-color: #f0f6ff;
}

.audit-report-button.button.new {
  height: 60px;
  padding: 10px 20px;
  border-top-left-radius: 20px;
  background-color: #f0f6ff;
}

.paragraph-12 {
  display: inline-block;
}

.paragraph-12.right-10 {
  margin-right: 10px;
}

.paragraph-12.right-10.down-0 {
  margin-bottom: 0px;
}

.paragraph-12.b-10 {
  margin-bottom: 10px;
}

.paragraph-12._00 {
  margin: 0px;
  padding: 0px;
  color: #7e7e7e;
}

.paragraph-12._00.right-10 {
  margin-right: 10px;
  font-size: 14px;
  line-height: 18px;
}

.paragraph-12._00.right-10.blue {
  color: #20a3f0;
}

.paragraph-12._00.right-10.blue.right-0 {
  margin-right: 0px;
}

.paragraph-12._00.right-10.blue {
  color: #20a3f0;
}

.paragraph-12.white {
  color: #fff;
}

.arrow-grey-icon {
  overflow: hidden;
  width: 24px;
  height: 24px;
  margin-top: 0px;
  margin-left: 0px;
  border-radius: 48px;
  background-color: #b3b3b3;
}

.arrow-grey-icon.graph {
  margin-top: 6px;
  margin-right: 6px;
}

.paragraph-13 {
  font-family: Icomoon, sans-serif;
}

.icon-moblyzicons_compass {
  font-family: Icomoon, sans-serif;
}

.icomoon {
  font-family: Icomoon, sans-serif;
  line-height: 32px;
  font-weight: 400;
}

.icomoon.white {
  color: #fff;
}

.icomoon.white.icon-1 {
  margin-top: 8px;
  padding-left: 4px;
}

.icomoon.arrow-icon {
  margin-bottom: 0px;
  padding-left: 4px;
  color: #20a3f0;
  font-size: 22px;
  line-height: 22px;
}

.icomoon.profile-icon {
  margin-bottom: 0px;
  padding-right: 12px;
  padding-left: 0px;
  color: #7e7e7e;
  font-size: 16px;
  line-height: 22px;
}

.icomoon._00 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #b3b3b3;
}

.icomoon._00.print-label {
  margin-top: -8px;
  margin-right: 7px;
  color: #20a3f0;
  font-size: 40px;
}

.icomoon._00.close-icon {
  color: #7e7e7e;
}

.icomoon._00.dashboard {
  margin-right: 12px;
}

.icomoon._00.white-tracking {
  margin-top: 4px;
  color: #fff;
  font-size: 35px;
}

.icomoon._00.profile-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 26px;
}

.icomoon._00.reporting-icon {
  color: #20a3f0;
  font-size: 26px;
  line-height: 20px;
}

.icomoon._00.value-icon {
  color: #20a3f0;
  font-size: 56px;
  line-height: 56px;
}

.icomoon._00.inline {
  display: inline-block;
}

.icomoon._00.inline.device-value {
  opacity: 0.9;
  color: #b0daf3;
  font-weight: 400;
}

.icomoon._00.close-icon {
  color: #333;
}

.icomoon._00.print-label {
  color: #20a3f0;
}

.icomoon._00.blue-primary {
  margin-bottom: -92px;
  color: #3cceda;
}

.icomoon._00.hot-pink {
  margin-bottom: -92px;
  color: #e786b2;
}

.icomoon._00.medium-spring {
  margin-bottom: -92px;
  color: #6ae7a3;
}

.icomoon._00.track-modal-phone {
  margin-top: -6px;
  margin-bottom: -2px;
  font-size: 20px;
  text-align: center;
}

.icomoon.eye {
  height: 21px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-right: 10px;
  color: #7e7e7e;
  font-size: 15px;
}

.icomoon.eye.raleway {
  font-family: Raleway, sans-serif;
  font-weight: 700;
}

.icomoon.download {
  height: 21px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-right: 10px;
  color: #fff;
  font-size: 14px;
}

.icomoon.arrow-icon {
  margin-bottom: 0px;
  padding-left: 4px;
  color: #45d445;
  font-size: 22px;
  line-height: 22px;
}

.icomoon.secondary-icon {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-right: 10px;
  color: #3cceda;
  font-size: 19px;
  line-height: 27px;
}

.graph-placeholder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 154px;
  height: 154px;
  margin-bottom: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0px none transparent;
  border-radius: 200px;
}

.graph-placeholder.pink {
  border-width: 0px;
  border-color: transparent;
}

.graph-placeholder.green {
  border-color: transparent;
}

.graph-placeholder.blue {
  border-color: #20a3f0;
}

.graph-placeholder.blue.mini {
  width: 22px;
  height: 22px;
  margin-right: 6px;
  margin-bottom: 0px;
  border-width: 4px;
}

.shipment-placeholder {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 16px;
}

.right-sidebar-top {
  position: relative;
  width: 100%;
  padding: 0px 60px 20px 0px;
}

.right-sidebar-top.hidden {
  display: none;
}

.audit-white-button {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-bottom-style: none;
  background-color: transparent;
}

.audit-white-button:hover {
  border-bottom-width: 0px;
  background-color: #f1f1f1;
}

.audit-white-button.absolute {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  margin-bottom: -4px;
  border-top-left-radius: 20px;
  background-color: #fff;
}

.upper-div-logout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logput-demo-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.form-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-flex.up-24 {
  margin-top: 24px;
}

.form-flex.vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.form-flex.flex-box-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.form-flex.flex-box-vertical.sort-filter {
  margin-top: 20px;
  padding: 5px 10px 3px;
  border-radius: 20px;
  background-color: #fff;
}

.form-flex.flex-box-vertical.rows-drodown {
  padding: 0px;
  border-radius: 14px;
  background-color: #fff;
}

.program-flex {
  position: absolute;
  margin: -6px 10px 0px 6px;
  color: #7e7e7e;
  font-size: 14px;
  font-weight: 400;
}

.program-flex.program-label {
  margin-top: -25px;
  margin-left: 18px;
  color: #7e7e7e;
  font-size: 13px;
  font-weight: 400;
}

.logout-div {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 300px;
  padding: 10px 20px 0px;
  border-bottom-left-radius: 30px;
  background-color: #e4f0fb;
  box-shadow: -1px 20px 20px -19px rgba(108, 134, 221, 0.1);
  color: #7e7e7e;
}

.div-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.quick-links-div {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-top: 32px;
  padding-top: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 24px;
  background-image: linear-gradient(135deg, #fff, hsla(0, 0%, 100%, 0.1));
  box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.1);
  text-align: center;
}

.quick-links-div.last-div {
  margin-top: 160px;
}

.quick-links-div.blue {
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border-style: none;
  background-image: linear-gradient(135deg, #edf2fc, #f6fbff);
  box-shadow: 0 20px 20px -20px rgba(87, 132, 192, 0.1);
}

.quick-links-inner-div {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
  margin-left: 0px;
  padding: 16px 16px 16px 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 24px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.8)), to(hsla(0, 0%, 100%, 0.8)));
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0.8));
  text-align: center;
  cursor: pointer;
}

.quick-links-inner-div.last-div {
  margin-top: 160px;
}

.transparent-inner-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-right: 16px;
  margin-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 48px;
}

.transparent-inner-icon.pink {
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(231, 134, 178, 0.7), #e786b2);
}

.transparent-inner-icon.green {
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(106, 231, 163, 0.8), #6ae7a3);
}

.transparent-inner-icon.blue {
  background-image: radial-gradient(circle farthest-corner at 50% 50%, rgba(32, 163, 240, 0.5), #20a3f0);
}

.transparent-inner-icon.close {
  margin-right: 0px;
}

.transparent-inner-icon.close.absolute {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 2;
  margin-top: 10px;
  margin-right: 10px;
}

.transparent-inner-icon._00 {
  margin-right: 0px;
}

.transparent-inner-icon._10 {
  margin-right: 10px;
}

.div-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.full-screen {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #f0f6ff;
  mix-blend-mode: soft-light;
}

.full-screen.pop1 {
  z-index: 2;
  width: 100vw;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
}

.full-screen.pop2 {
  display: none;
}

.full-screen.pop3 {
  display: none;
}

.full-screen.dark {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.full-screen.dark.new {
  display: block;
  width: 100%;
  height: auto;
  -o-object-fit: contain;
  object-fit: contain;
}

.popup-form-bg {
  position: relative;
  display: block;
  overflow: hidden;
  width: 800px;
  margin-right: 15%;
  margin-bottom: 184px;
  margin-left: 15%;
  padding: 40px;
  clear: both;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  border-radius: 24px;
  background-color: #fff;
  -o-object-fit: fill;
  object-fit: fill;
}

.popup-form-bg.long-width {
  width: 960px;
}

.popup-form-bg.long-width.left {
  margin-left: 0px;
}

.popup-form-bg.long-width.full {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
}

.popup-form-bg.long-width-copy {
  width: 960px;
}

.popup-form-bg.long-width-copy.left {
  margin-left: 0px;
}

.popup-form-bg.long-width-copy.full {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
}

.popup-form-bg.forstyleguide {
  width: 80%;
  min-width: 500px;
}

.form-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  font-weight: 400;
}

.plus-horizontal {
  width: 12px;
  height: 2px;
  border-radius: 1px;
  background-color: #1e2245;
}

.faq-text {
  font-weight: 400;
}

.plus-vertical {
  position: absolute;
  width: 2px;
  height: 12px;
  border-radius: 1px;
  background-color: #1e2245;
}

.faq-answer {
  display: none;
  overflow: hidden;
  padding-right: 40px;
}

.faq-answer.box {
  display: block;
  padding: 0px 24px 0px 20px;
  border-radius: 0px 0px 5px 5px;
}

.faq-answer.box.bg-l-purple {
  display: block;
  border-top: 2px solid #fff;
  background-color: #f0f6ff;
}

.faq-element.box {
  margin-bottom: 24px;
  padding: 0px;
  border-radius: 5px;
  background-color: #fff;
}

.faq-question {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.faq-question.box {
  min-height: 56px;
  padding: 16px 20px;
  border-radius: 5px 5px 0px 0px;
  background-color: #f0f6ff;
  font-weight: 300;
}

.plus-div {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 12px;
  height: 12px;
  margin-left: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.answer-spacing {
  margin-top: 24px;
}

.shipping-label-form {
  margin-top: 62px;
}

.div-block-7 {
  width: 100%;
  margin-top: 0px;
  margin-right: 10px;
}

.div-block-7.up-24 {
  margin-top: 0px;
}

.request-packaging-shipping-label {
  margin-top: 62px;
}

.request-packaging-shipping-label.up-extra {
  margin-top: 70px;
}

.pickup-team-request {
  margin-top: 62px;
}

.field-label-2 {
  font-weight: 600;
}

.checkbox-label {
  font-weight: 400;
}

.table-relative {
  position: relative;
  width: 130vw;
  height: 100%;
}

.grid-3 {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.dashboard-table {
  display: -ms-grid;
  display: grid;
  width: 100vw;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.dashboard-table.relative {
  position: relative;
}

.table-wrapper {
  overflow: auto;
  padding-top: 3px;
}

.table-wrapper.up-margin {
  margin-top: 40px;
}

.table-div {
  width: 100%;
  padding: 8px 12px;
  background-color: #fff;
}

.table-div._1 {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  padding-right: 8px;
  padding-left: 8px;
  border: 1px none #000;
  background-color: #fff;
}

.table-div._1.grey {
  border: 1px none #000;
}

.table-div._2 {
  position: -webkit-sticky;
  position: sticky;
  right: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 8px 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.table-div.grey {
  background-color: #f2f2f2;
}

.table-div.heading {
  margin-left: 0px;
  padding-right: 22px;
  border: 1px none #000;
  background-color: #fff;
}

.black-link {
  color: #333;
}

.single-table {
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.single-table.sticky {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  box-shadow: 5px 5px 15px 0 rgba(200, 208, 220, 0.2);
}

.single-table.r-sticky {
  position: -webkit-sticky;
  position: sticky;
  left: auto;
  right: 0px;
  padding-right: 0px;
  box-shadow: -2px 0 17px -3px #b6cbdc;
}

.table-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}

.table-heading.report-inner-heading {
  margin-top: 0px;
  margin-bottom: 10px;
}

.table-heading.report-inner-heading.new {
  margin-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.table-heading.report-inner-heading.new {
  margin-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.table-heading.value {
  margin-top: 0px;
  margin-bottom: 10px;
  color: #20a3f0;
}

.table-heading.value.new {
  margin-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.table-heading.settings-iner-heading {
  margin-top: 0px;
  margin-bottom: 0px;
}

.table-heading.settings-iner-heading.new {
  margin-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.table-heading.settings-iner-heading.new {
  margin-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.table-main-div {
  margin-top: 40px;
  padding-right: 0px;
  padding-bottom: 100px;
}

.div-block-8 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 16px;
  -ms-grid-columns: minmax(270px, 270px) 1fr;
  grid-template-columns: minmax(270px, 270px) 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.trackingtable-main-div {
  padding-top: 180px;
  padding-right: 60px;
  padding-bottom: 100px;
}

.trackingtable-main-div._1 {
  padding-top: 150px;
}

.sort-form {
  margin-bottom: 0px;
}

.sort-form.rows {
  width: 70px;
  margin-right: 10px;
  margin-left: 10px;
}

.div-block-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.column-button-paragraph {
  margin-bottom: 0px;
  padding-top: 4px;
}

.div-block-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 22px 10px;
  border-radius: 20px;
  background-color: #fff;
}

.div-block-10.green {
  margin-top: 0px;
  padding: 8px 22px 10px;
  border-radius: 25px;
  background-color: #3cceda;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #fff;
  font-weight: 300;
}

.div-block-10.green:hover {
  background-color: #333;
}

.div-block-10.white {
  padding: 8px 22px 10px;
  border-radius: 25px;
  background-color: #fff;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
}

.div-block-11 {
  position: relative;
  padding: 20px 10px 10px;
}

.div-block-12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.previus-div {
  margin-right: 10px;
  padding: 6px 10px;
  border-style: none;
  border-width: 1px;
  border-color: #333;
  border-radius: 4px;
  background-color: #2974a0;
  line-height: 20px;
}

.number-text {
  margin-bottom: 0px;
  color: #fff;
}

.number-text.black {
  color: #333;
}

.number-div {
  margin-right: 10px;
  padding: 6px 10px;
  border-radius: 4px;
  background-color: #2974a0;
  line-height: 20px;
}

.number-div.white {
  background-color: #fff;
  line-height: 20px;
}

.number-div.white.transparent {
  background-color: transparent;
}

.div-block-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.field-label-3 {
  font-style: normal;
  font-weight: 500;
}

.field-label-4 {
  font-style: normal;
  font-weight: 500;
}

.field-label-5 {
  font-weight: 500;
}

.body-2 {
  font-family: 'Cera pro', sans-serif;
  font-weight: 400;
}

.block-quote {
  border-radius: 20px;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  color: #7474bf;
  font-size: 2em;
  line-height: 1em;
}

.top-line {
  width: 16px;
  height: 2px;
  margin-top: 0px;
  border-radius: 5px;
  background-color: #b3b3b3;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggle-ticker {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  margin-left: 0px;
  background-color: #7e7e7e;
  opacity: 0;
  cursor: pointer;
}

.column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.form-wrapper {
  margin-bottom: 0px;
}

.toggle {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  margin-top: 3px;
  margin-left: 3.5px;
  border-radius: 100px;
  background-color: #fff;
}

.toggle-wrap {
  position: relative;
  display: block;
  width: 70px;
  height: 38px;
  max-height: 38px;
  max-width: 70px;
  margin-bottom: 0px;
  margin-left: auto;
  padding-left: 0px;
  border-radius: 25px;
  background-color: #dde6ee;
}

.toggle-active {
  position: relative;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 100px;
  text-align: center;
}

.active-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background-color: #7474bf;
  box-shadow: -3px 0 3px 0 rgba(116, 116, 191, 0.35);
  opacity: 0;
}

.bottom-line {
  position: relative;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 16px;
  height: 2px;
  margin-top: -2px;
  border-radius: 5px;
  background-color: #b3b3b3;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.toggle-label {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  height: 100%;
}

.container-2 {
  position: relative;
  top: 50%;
  display: block;
  width: 500px;
  margin-right: auto;
  margin-left: auto;
  padding: 50px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 20px;
  background-color: #f0f6ff;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.11);
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  text-align: center;
}

.div-block-14 {
  margin-top: 67px;
}

.returnline1 {
  position: absolute;
  left: 0px;
  top: 42px;
  right: auto;
  bottom: 42px;
  width: 40%;
  height: 10px;
  border-bottom: 6px solid rgba(105, 180, 238, 0.97);
  background-color: transparent;
}

.topsteparrow {
  position: absolute;
  left: auto;
  top: -3px;
  right: -20px;
  z-index: 100;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent transparent rgba(64, 89, 216, 0.34);
  border-radius: 0px;
  background-color: transparent;
}

.trackingmod {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  max-width: 100%;
  min-height: 352px;
  border-radius: 40px 40px 0px 0px;
  background-color: transparent;
  background-image: none;
  box-shadow: 1px 0 10px 0 rgba(51, 51, 51, 0.1);
}

.usercircleicondiv {
  margin-top: 14px;
  color: #b2cce0;
  text-align: center;
}

.outroute {
  position: relative;
  width: 50%;
  height: auto;
  min-height: 350px;
  float: left;
  border-radius: 1px;
}

.returnline3 {
  position: absolute;
  left: auto;
  top: 67px;
  right: 8%;
  bottom: auto;
  width: 45%;
  height: 64%;
  border-style: solid solid solid none;
  border-width: 6px;
  border-color: transparent rgba(105, 180, 238, 0.97) transparent #06eb3f;
  border-radius: 0px 75px 75px 0px;
  background-color: transparent;
}

.devicecirclename {
  margin-top: 4px;
  color: #6d99af;
  text-align: center;
}

.usercirclename {
  margin-top: 4px;
  color: #6d99af;
  text-align: center;
}

.text-span-4 {
  font-size: 9px;
}

.statustag {
  position: absolute;
  left: 9px;
  top: -42px;
  z-index: 202;
  width: 130px;
  height: 50px;
  background-color: transparent;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
}

.statusta8 {
  position: absolute;
  left: -53px;
  top: -38px;
  z-index: 202;
  width: 130px;
  height: 50px;
  background-color: transparent;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
}

.returnline5 {
  position: absolute;
  left: 60px;
  top: auto;
  right: auto;
  bottom: 42px;
  width: 30%;
  height: 10px;
  border-bottom: 6px solid rgba(105, 180, 238, 0.97);
  background-color: transparent;
}

.statusta3 {
  position: absolute;
  left: 57px;
  top: 44px;
  right: -126px;
  z-index: 202;
  width: 130px;
  height: 50px;
  background-color: transparent;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
}

.outline5 {
  position: absolute;
  top: 46px;
  right: 40%;
  bottom: auto;
  width: 45%;
  height: 100px;
  border-style: solid none solid solid;
  border-width: 6px 0px 6px 6px;
  border-color: rgba(84, 132, 228, 0.47) transparent transparent;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  background-color: transparent;
}

.rettracktag {
  position: absolute;
  left: auto;
  top: 50%;
  right: 25%;
  z-index: 105;
  width: 150%;
  height: 40px;
  margin-top: -20px;
  padding-top: 0px;
  padding-left: 80px;
  border-radius: 20px;
  background-image: linear-gradient(203deg, rgba(73, 160, 226, 0.2), rgba(97, 100, 223, 0.16));
  color: #aad7f5;
  font-size: 9px;
}

.outline2 {
  position: absolute;
  right: 0px;
  bottom: 42px;
  width: 40%;
  height: 10px;
  border-bottom: 6px solid #02f32e;
  background-color: transparent;
}

.outline2.stepline {
  bottom: 42px;
  width: 42%;
  border-right: 10px none #000;
  border-bottom-color: rgba(105, 180, 238, 0.97);
}

.stepline.highlighted {
  border-bottom-color: #63f663;
}

.text-span-3 {
  position: absolute;
  margin-top: 0px;
  margin-left: 1px;
  color: #2974a0;
}

.statusta7 {
  position: absolute;
  left: -68px;
  top: -25px;
  right: auto;
  z-index: 202;
  width: 130px;
  height: 65px;
  background-color: transparent;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
}

.outline6 {
  position: absolute;
  left: 52%;
  top: 42px;
  right: auto;
  bottom: 42px;
  width: 25%;
  height: 10px;
  border-bottom: 6px solid rgba(84, 132, 228, 0.47);
  background-color: transparent;
}

.outtracktaglabel {
  position: absolute;
  margin-top: 0px;
  margin-left: 1px;
  color: #2974a0;
}

.div-block-15 {
  position: absolute;
  left: -100px;
  top: 130px;
  z-index: 110;
  width: 200px;
  height: 140px;
  border-radius: 15px;
  background-image: linear-gradient(10deg, #f0fdff, #fff 73%);
  box-shadow: 0 40px 25px 4px rgba(43, 70, 131, 0.05);
}

.returnline4 {
  position: absolute;
  top: auto;
  right: 11%;
  bottom: 42px;
  width: 45%;
  height: 100px;
  border-style: none solid solid;
  border-width: 6px 6px 6px 0px;
  border-color: #00fd48 transparent rgba(105, 180, 238, 0.97);
  border-radius: 0px 50px 500px 0px;
  background-color: transparent;
}

.returnline2 {
  position: absolute;
  left: 40%;
  top: 46px;
  right: auto;
  bottom: auto;
  width: 45%;
  height: 100px;
  border-style: solid;
  border-width: 6px 6px 6px 0px;
  border-color: rgba(105, 180, 238, 0.97) transparent transparent;
  border-radius: 0px 50px 0px 0px;
  background-color: transparent;
}

.statusta5 {
  position: absolute;
  left: -53px;
  top: 57px;
  z-index: 202;
  width: 130px;
  height: 50px;
  background-color: transparent;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
}

.devicecircleinfo {
  margin-top: 5px;
  color: #a5b5bd;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
}

.statusta4 {
  position: absolute;
  left: 10px;
  top: 57px;
  z-index: 202;
  width: 130px;
  height: 50px;
  background-color: transparent;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
}

.usercircleinfo {
  margin-top: 5px;
  color: #a5b5bd;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
}

.devicecircleicondiv {
  margin-top: 0px;
  color: #b2cce0;
  text-align: center;
}

.text-span {
  position: absolute;
  margin-top: -9px;
  margin-left: 2px;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  color: #2974a0;
  font-size: 15px;
}

.outtracktag {
  position: absolute;
  left: 25%;
  top: 50%;
  z-index: 105;
  width: 150%;
  height: 40px;
  margin-top: -20px;
  padding-right: 16px;
  padding-left: 47px;
  border-radius: 20px;
  background-image: linear-gradient(203deg, rgba(73, 160, 226, 0.2), rgba(97, 100, 223, 0.16));
  font-family: 'Open Sans', sans-serif;
  font-size: 9px;
}

.statusta2 {
  position: absolute;
  left: 57px;
  top: -35px;
  right: auto;
  bottom: auto;
  z-index: 202;
  width: 120px;
  height: 50px;
  background-color: transparent;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
}

.returnroute {
  position: relative;
  width: 50%;
  height: auto;
  min-height: 350px;
  float: right;
  clear: none;
  border-radius: 1px;
}

.outline3 {
  position: absolute;
  right: 40%;
  bottom: 42px;
  width: 45%;
  height: 100px;
  border-style: none none solid solid;
  border-width: 0px 0px 6px 6px;
  border-color: transparent transparent #02f32e;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  background-color: transparent;
}

.outline3.stepline {
  right: 42%;
  border-bottom-color: rgba(105, 180, 238, 0.97);
}

.text-span-5 {
  color: #4b48e4;
  font-size: 11px;
}

.bottomsteparrow {
  position: absolute;
  left: -20px;
  top: -3px;
  right: auto;
  z-index: 100;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px;
  border-color: transparent rgba(67, 174, 219, 0.65) transparent transparent;
  border-radius: 0px;
  background-color: transparent;
}

.text-span-2 {
  left: auto;
  display: block;
  margin-top: -9px;
  margin-left: 2px;
  color: #2974a0;
  font-size: 15px;
}

.stepcircle {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  overflow: visible;
  width: 55px;
  height: 55px;
  max-height: 55px;
  max-width: 55px;
  min-height: 55px;
  min-width: 55px;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: #8fbae4;
  box-shadow: 0 20px 30px -3px rgba(0, 138, 25, 0.11);
  color: #22dd2e;
  -o-object-fit: fill;
  object-fit: fill;
  z-index: 101;
}

.stepcircle.step5 {
  left: auto;
  top: 6%;
  right: 50%;
}

.stepcircle.step1 {
  left: 50%;
  top: auto;
  bottom: 20px;
}

.stepcircle.step2 {
  left: 10%;
  top: auto;
  bottom: 15%;
}

.stepcircle.step8 {
  left: auto;
  top: auto;
  right: 50%;
  bottom: 6%;
}

.stepcircle.step6 {
  left: auto;
  top: 15%;
  right: 10%;
  z-index: 101;
}

.stepcircle.step4 {
  left: 50%;
  top: 6%;
}

.stepcircle.step3 {
  left: 10%;
  top: 15%;
}

.stepcircle.highlighted {
  background-color: #63f663;
}

.stepcircle.step7 {
  left: auto;
  top: auto;
  right: 10%;
  bottom: 60px;
}

.outline4 {
  position: absolute;
  left: 8%;
  top: 67px;
  right: auto;
  bottom: auto;
  width: 45%;
  height: 64%;
  border-style: solid none solid solid;
  border-width: 6px 0px 6px 6px;
  border-color: transparent transparent transparent rgba(105, 180, 238, 0.97);
  border-top-left-radius: 75px;
  border-bottom-left-radius: 75px;
  background-color: transparent;
}

.outline4.highlighted {
  border-left-color: #63f663;
}

.detailscircle {
  position: absolute;
  left: -22px;
  top: auto;
  bottom: 8px;
  z-index: 201;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-image: linear-gradient(175deg, #effbff, #fff 38%);
  box-shadow: 0 40px 20px 2px rgba(51, 35, 78, 0.03);
}

.statusta6 {
  position: absolute;
  left: -67px;
  top: 28px;
  right: auto;
  z-index: 202;
  width: 130px;
  height: 65px;
  background-color: transparent;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
}

.usercircle {
  position: absolute;
  left: -80px;
  top: -48px;
  z-index: 201;
  width: 160px;
  height: 160px;
  padding-top: 10px;
  border-radius: 50%;
  background-image: linear-gradient(33deg, #effbff, #fff);
  box-shadow: 0 40px 20px 2px rgba(51, 35, 78, 0.03);
}

.deviceinfotop {
  position: absolute;
  left: 76px;
  top: -40px;
  z-index: 100;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 25px 25px 15px;
  border-color: transparent transparent #fff;
  border-radius: 0px;
  background-color: transparent;
}

.deviceinfocirclebottom {
  position: absolute;
  left: 7px;
  top: auto;
  bottom: -9px;
  z-index: 100;
  width: 0px;
  height: 0px;
  margin-bottom: -3px;
  border-style: solid;
  border-width: 15px;
  border-color: transparent transparent #fff;
  border-radius: 0px;
  background-color: transparent;
}

.modal-content .div-block-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* width: 60%;
  max-width: 80%; */
  width: 100%;
  max-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 40px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#8ec5fc), to(#c5d4ff));
  background-image: linear-gradient(180deg, #8ec5fc, #c5d4ff);
}

.white-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 46px;
  padding-right: 5%;
  padding-left: 5%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.white-div.center {
  height: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.white-div.small {
  height: 40px;
}

.white-div.small.last {
  height: 60px;
  padding-bottom: 20px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.white-div.report-list {
  height: 38px;
}

.tracking-popup-heading {
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 18px;
}

.tracking-map {
  height: 380px;
}

.signs-grid {
  margin-top: 20px;
  margin-bottom: 20px;
  grid-column-gap: 300px;
  grid-row-gap: 8px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.image {
  padding-bottom: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.notice-banner-div {
  display: -ms-grid;
  display: grid;
  margin-bottom: 40px;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.25fr 1.75fr;
  grid-template-columns: 0.25fr 1.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 150px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #20a3f0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #fff;
}

.para-icon {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 150px;
  margin-left: -1px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #20a3f0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #fff;
}

.tracking-search-div {
  width: 100%;
  padding: 40px 10%;
  border-radius: 20px;
  background-color: #f0f6ff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(32, 163, 240, 0.4)), to(rgba(32, 163, 240, 0.4)));
  background-image: linear-gradient(180deg, rgba(32, 163, 240, 0.4), rgba(32, 163, 240, 0.4));
}

.device-value-search {
  margin-top: 0px;
}

.device-value-search.up-extra {
  margin-top: 70px;
}

.device-value-search.device-detailed {
  margin-top: 20px;
  margin-bottom: 40px;
}

.device-bg {
  width: 100%;
  max-width: 1300px;
  padding: 0px 0%;
  border-radius: 40px;
  background-color: #f0f6ff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(32, 163, 240, 0.4)), to(rgba(32, 163, 240, 0.4)));
  background-image: linear-gradient(180deg, rgba(32, 163, 240, 0.4), rgba(32, 163, 240, 0.4));
}

.device-value-grid {
  height: 450px;
  max-width: 1300px;
  -ms-grid-columns: 2.75fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 2.75fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 40px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#b3e3f1), color-stop(36%, #8bc3e5), to(#bdc5e7));
  background-image: linear-gradient(180deg, #b3e3f1, #8bc3e5 36%, #bdc5e7);
}

.column-1 {
  position: relative;
  width: 100%;
  padding-right: 0%;
  padding-left: 0%;
}

.mini-column {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  margin-left: -17px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(60, 176, 218, 0.4)), to(transparent));
  background-image: linear-gradient(180deg, rgba(60, 176, 218, 0.4), transparent);
}

.circle-grade {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 6;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #20a3f0;
  background-image: linear-gradient(135deg, #20a3f0, #5ce4f0);
  box-shadow: 0 20px 18px -3px rgba(38, 135, 190, 0.2);
}

.info-mini {
  position: absolute;
  z-index: 4;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 80px;
  padding-top: 3px;
  padding-right: 6px;
  padding-bottom: 3px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-radius: 12px;
  background-color: #20a3f0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(51, 51, 51, 0.2)), to(rgba(51, 51, 51, 0.2)));
  background-image: linear-gradient(180deg, rgba(51, 51, 51, 0.2), rgba(51, 51, 51, 0.2));
}

.div-block-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 110px;
  margin-top: -10px;
  padding-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.div-block-17.right {
  width: 100%;
  height: 60px;
  margin-top: 0px;
  margin-left: 0px;
  padding-right: 3%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 40px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(60, 206, 218, 0.4)), to(transparent));
  background-image: linear-gradient(180deg, rgba(60, 206, 218, 0.4), transparent);
}

.div-block-18 {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  margin-left: -10%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.price-circle {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  height: 120px;
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: transparent;
  box-shadow: 0 -20px 20px 0 rgba(36, 133, 189, 0.16);
}

.price-circle.a {
  margin-top: 100px;
  box-shadow: 0 -20px 36px -7px rgba(43, 134, 187, 0.2);
}

.price-circle.b {
  margin-top: 130px;
}

.price-circle.c {
  margin-top: 170px;
}

.price-circle.d {
  margin-top: 210px;
}

.price-circle.f {
  margin-top: 250px;
}

.device-image {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 280px;
  height: 280px;
  margin: 0px 0px -20px -20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 20px 50px -18px rgba(51, 144, 197, 0.2);
}

.device-image.a {
  margin-top: 100px;
}

.device-image.b {
  margin-top: 130px;
}

.device-image.c {
  margin-top: 170px;
}

.device-image.d {
  margin-top: 210px;
}

.device-image.f {
  margin-top: 250px;
}

.image-2 {
  width: 130px;
  margin-right: 10px;
}

.div-block-19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.full-screen-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #f0f6ff;
  mix-blend-mode: soft-light;
}

.full-screen-2.dark {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.full-screen-2.dark.new {
  height: auto;
}

.popup-form-bg-2 {
  position: relative;
  overflow: hidden;
  width: 600px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  padding: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 24px;
  background-color: #fff;
}

.popup-form-bg-2.long-width {
  width: 960px;
}

.form-heading-div-2 {
  margin-bottom: 30px;
  border-bottom: 1px solid #b3b3b3;
}

.form-heading-div-2.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.form-heading-div-2.flex.form {
  padding-bottom: 8px;
}

.form-heading-div-2.flex.form.absolute {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 1;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 40px;
  border-bottom-style: none;
  background-color: #f0f6ff;
}

.input-field-2 {
  display: inline-block;
  width: 100%;
  min-height: 56px;
  margin-bottom: 24px;
  padding: 24px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #b3b3b3;
  border-radius: 20px;
  background-color: #fff;
  -webkit-transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease;
  color: #333;
  font-size: 16px;
}

.success-message-2 {
  background-color: #f0f6ff;
}

.primary-btn-2 {
  padding: 15px 32px 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #3cceda;
  border-radius: 20px;
  background-color: #3cceda;
  box-shadow: none;
  -webkit-transition: color 400ms ease, -webkit-transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  transition: color 400ms ease, -webkit-transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  transition: color 400ms ease, transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  transition: color 400ms ease, transform 200ms cubic-bezier(.175, .885, .32, 1.275), -webkit-transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  color: #fff;
  font-weight: 400;
}

.primary-btn-2:hover {
  background-color: #fff;
  box-shadow: none;
  color: #3cceda;
}

.primary-btn-2:active {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.primary-btn-2:focus {
  box-shadow: 0 0 3px 1px rgba(0, 0, 255, 0.48);
}

.primary-btn-2.full {
  width: 100%;
}

.primary-btn-2.full.form-button {
  margin-top: 20px;
}

/* .select-2 {
  width: 100%;
  min-height: 56px;
  margin-bottom: 24px;
  padding: 8px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #b3b3b3;
  border-radius: 20px;
  background-color: #fff;
  background-image: url('../images/Chevron-down.svg');
  background-position: 96% 50%;
  background-size: 16px;
  background-repeat: no-repeat;
  -webkit-transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease, -webkit-transform 200ms cubic-bezier(.19, 1, .22, 1);
  transition: color 200ms ease, background-color 200ms ease, border-color 200ms ease, -webkit-transform 200ms cubic-bezier(.19, 1, .22, 1);
  transition: transform 200ms cubic-bezier(.19, 1, .22, 1), color 200ms ease, background-color 200ms ease, border-color 200ms ease;
  transition: transform 200ms cubic-bezier(.19, 1, .22, 1), color 200ms ease, background-color 200ms ease, border-color 200ms ease, -webkit-transform 200ms cubic-bezier(.19, 1, .22, 1);
  color: #333;
  font-size: 16px;
  cursor: pointer;
} */

.select-2:focus {
  border-style: solid;
  color: #0c2652;
}

.select-2::-webkit-input-placeholder {
  color: #8d8c8c;
}

.select-2:-ms-input-placeholder {
  color: #8d8c8c;
}

.select-2::-ms-input-placeholder {
  color: #8d8c8c;
}

.select-2::placeholder {
  color: #8d8c8c;
}

.audit-white-button-2 {
  border-bottom-style: none;
  background-color: #fff;
}

.audit-white-button-2:hover {
  border-bottom-width: 0px;
  background-color: #f1f1f1;
}

.audit-white-button-2.absolute {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  border-top-left-radius: 20px;
}

.image-3 {
  padding-bottom: 10px;
  border-bottom: 1px solid #b3b3b3;
}

.support-form {
  margin-top: 50px;
}

.support-form.up-extra {
  margin-top: 70px;
}

.button-inner-text {
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 0px;
  color: #3cceda;
}

.secondary-button-with-icon {
  display: inline-block;
  padding: 12px 32px 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #3cceda;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: none;
  -webkit-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: border-color 300ms ease, color 400ms ease, -webkit-transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  transition: border-color 300ms ease, color 400ms ease, -webkit-transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  transition: border-color 300ms ease, color 400ms ease, transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  transition: border-color 300ms ease, color 400ms ease, transform 200ms cubic-bezier(.175, .885, .32, 1.275), -webkit-transform 200ms cubic-bezier(.175, .885, .32, 1.275);
  color: #3cceda;
  font-weight: 500;
}

.secondary-button-with-icon:hover {
  background-color: #f1f1f1;
  box-shadow: none;
}

.secondary-button-with-icon:active {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.secondary-button-with-icon:focus {
  box-shadow: 0 0 3px 1px rgba(0, 0, 255, 0.48);
}

.secondary-button-with-icon.save-form {
  margin-bottom: -6px;
}

.secondary-button-with-icon.save-form._2 {
  height: 59px;
  text-align: center;
}

.secondary-button-with-icon.hidden {
  display: none;
}

.secondary-button-with-icon.mini {
  padding: 2px 22px 6px;
}

.icomoon-withicon {
  font-family: Icomoon, sans-serif;
  line-height: 32px;
  font-weight: 400;
}

.icomoon-withicon.white {
  color: #fff;
}

.icomoon-withicon.white.icon-1 {
  margin-top: 8px;
  padding-left: 4px;
}

.icomoon-withicon.arrow-icon {
  margin-bottom: 0px;
  padding-left: 4px;
  color: #45d445;
  font-size: 22px;
  line-height: 22px;
}

.icomoon-withicon.profile-icon {
  margin-bottom: 0px;
  padding-right: 4px;
  padding-left: 0px;
  color: #45d445;
  font-size: 22px;
  line-height: 22px;
}

.icomoon-withicon._00 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.icomoon-withicon._00.print-label {
  color: #20a3f0;
}

.icomoon-withicon._00.close-icon {
  color: #333;
}

.icomoon-withicon._00.dashboard {
  margin-right: 12px;
}

.icomoon-withicon._00.white-tracking {
  margin-top: 4px;
  color: #fff;
  font-size: 40px;
}

.icomoon-withicon._00.profile-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 26px;
}

.icomoon-withicon._00.reporting-icon {
  color: #20a3f0;
  font-size: 26px;
  line-height: 20px;
}

.icomoon-withicon._00.value-icon {
  color: #20a3f0;
  font-size: 56px;
  line-height: 56px;
}

.icomoon-withicon._00.inline {
  display: inline-block;
}

.icomoon-withicon._00.inline.device-value {
  opacity: 0.9;
  color: #fff;
}

.icomoon-withicon._00.close-icon {
  color: #333;
}

.icomoon-withicon._00.print-label {
  color: #20a3f0;
}

.icomoon-withicon.eye {
  height: 21px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-right: 10px;
  font-size: 20px;
}

.icomoon-withicon.eye.raleway {
  font-family: Raleway, sans-serif;
  font-weight: 700;
}

.icomoon-withicon.download {
  height: 21px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-right: 10px;
  color: #fff;
  font-size: 20px;
}

.icomoon-withicon.arrow-icon {
  margin-bottom: 0px;
  padding-left: 4px;
  color: #45d445;
  font-size: 22px;
  line-height: 22px;
}

.icomoon-withicon.secondary-icon {
  display: inline;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-right: 10px;
  color: #3cceda;
  font-size: 18px;
  line-height: 27px;
}

.heading-button-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-button-div.bottom-setting-line {
  padding-bottom: 20px;
  border-bottom: 1px solid #b3b3b3;
}

.heading-button-div.up {
  margin-top: 20px;
}

.setting-grid {
  margin-top: 32px;
  grid-column-gap: 32px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-20 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-4 {
  margin-bottom: 30px;
  grid-column-gap: 32px;
}

.grid-5 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.legends-circle {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  border-radius: 50%;
}

.legends-circle._1-1 {
  background-color: #b3ffad;
}

.legends-circle._1-2 {
  background-color: #45e5c5;
}

.legends-circle._1-3 {
  background-color: #00c5db;
}

.legends-circle._1-4 {
  background-color: #00a0dd;
}

.legends-circle._1-5 {
  background-color: #4c77c2;
}

.legends-circle._2-1 {
  background-color: #6782c2;
}

.legends-circle._2-2 {
  background-color: #9983c7;
}

.legends-circle._2-3 {
  background-color: #c583c1;
}

.legends-circle._2-4 {
  background-color: #e786b2;
}

.legends-circle._2-5 {
  background-color: #ff8f9e;
}

.legends-circle._3-1 {
  background-color: #6ae7a3;
}

.legends-circle._3-2 {
  background-color: #e2ff6e;
}

.legends-main-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 6px 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.legends {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  min-height: 78px;
  margin-bottom: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.div-block-21 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bold-text {
  color: #0c2652;
}

.detailslink {
  position: relative;
  margin-top: 4px;
  margin-left: 2px;
  padding-top: 0px;
  padding-bottom: 2px;
  font-size: 14px;
  line-height: 20px;
}

.field-label-in-blue-row {
  position: absolute;
  left: 20px;
  top: 30px;
  right: auto;
  bottom: auto;
}

.div-block-22 {
  position: relative;
}

.div-block-23 {
  position: relative;
}

.field-row-blue-label {
  position: absolute;
  left: 20px;
  top: 2px;
  right: auto;
  bottom: auto;
  font-size: 15px;
}

.div-block-24 {
  position: relative;
}

.div-block-25 {
  position: relative;
}

.div-block-25.border-right {
  margin-bottom: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
  border-right: 1px solid #ddd;
  border-bottom: 4px solid transparent;
}

.div-block-26 {
  position: relative;
}

.div-block-26.border-right {
  margin-bottom: 0px;
  border-right: 1px solid #ddd;
}

.div-block-27 {
  position: relative;
}

.track-modal-audit-label {
  font-size: 15px;
  font-weight: 500;
}

.bold-text-2 {
  font-weight: 400;
}

.html-embed {
  width: 100%;
}

.heading-6 {
  width: 15%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.heading-6.tracking-report-field {
  display: block;
  overflow: hidden;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-size: 14px;
  font-weight: 400;
  -o-object-fit: fill;
  object-fit: fill;
}

.heading-6.tracking-report-field.model-field {
  width: 15%;
  min-width: 15px;
  margin-right: 2%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.heading-7 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.heading-7.tracking-report-field {
  width: 15%;
  margin-right: 3%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.heading-7.tracking-report-field.carrier-field {
  width: 14%;
}

.tracking-report-field {
  width: 13%;
  margin-right: 2%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 14px;
}

.tracking-report-field.value-field {
  width: 7%;
}

.tracking-report-field.grade-field {
  width: 15%;
  font-weight: 400;
}

.tracking-report-field.serial-field {
  width: 14%;
}

.tracking-report-field.imei-field {
  width: 14%;
}

.bold-text-3 {
  font-weight: 400;
}

.bold-text-4 {
  font-weight: 400;
}

.bold-text-5 {
  font-weight: 400;
}

.bold-text-6 {
  font-weight: 400;
}

.bold-text-7 {
  font-weight: 400;
}

.bold-text-8 {
  font-weight: 400;
}

.bold-text-9 {
  font-weight: 400;
}

.tracking-report-list-wrap {
  overflow: scroll;
  width: 100%;
  max-height: 300px;
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 56px;
    line-height: 64px;
  }

  h2 {
    font-size: 48px;
    line-height: 56px;
  }

  h3 {
    font-size: 40px;
    line-height: 48px;
  }

  h4 {
    font-size: 32px;
    line-height: 40px;
  }

  h5 {
    font-size: 24px;
    line-height: 32px;
  }

  h6 {
    font-size: 16px;
    line-height: 24px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  ul {
    padding-left: 40px;
  }

  .class {
    margin: 40px;
  }

  .test {
    margin-top: 3.6vw;
    margin-right: 3.6vw;
  }

  .styleguide {
    max-width: 100%;
    padding: 20px;
  }

  .navbar {
    padding-left: 24px;
  }

  .navbar-link {
    margin-right: 32px;
  }

  .color-tiles {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .color-tile {
    height: 120px;
  }

  .style-item {
    -ms-grid-columns: 0.5fr 1fr;
    grid-template-columns: 0.5fr 1fr;
  }

  .header {
    padding-top: 80px;
    padding-bottom: 64px;
  }

  .primary-btn {
    padding-right: 25px;
    padding-left: 25px;
  }

  .container.medium {
    width: 90%;
  }

  .container.small {
    width: 90%;
  }

  .section.no-padding.two-column-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .left-div.light-purple.fixed {
    padding-right: 0px;
    padding-left: 0px;
  }

  .laptop-div {
    width: 80%;
  }

  .input-fields-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .navbar-link-2 {
    margin-right: 32px;
  }

  .dashboard-table.relative {
    width: 320vw;
  }

  .returnline1 {
    top: 51px;
  }

  .trackingmod {
    left: auto;
    top: 100px;
    width: 90%;
    height: 600px;
    max-width: 90%;
    margin-left: 5%;
  }

  .outroute {
    min-height: 500px;
  }

  .returnline3 {
    height: 72%;
  }

  .statusta3 {
    top: 56px;
    right: -32px;
    width: 60px;
    height: 120px;
  }

  .outline5 {
    top: 54px;
  }

  .statusta7 {
    top: -32px;
  }

  .outline6 {
    top: 51px;
  }

  .div-block-15 {
    height: 200px;
  }

  .returnline4 {
    right: 10%;
    bottom: 42px;
    height: 150px;
    border-bottom-right-radius: 250px;
  }

  .returnline2 {
    top: 54px;
  }

  .statusta5 {
    left: 3px;
    width: 60px;
    height: 100px;
  }

  .statusta4 {
    left: 1px;
    width: 60px;
    height: 100px;
  }

  .returnroute {
    min-height: 500px;
  }

  .outline3.stepline {
    left: 10%;
    right: auto;
    height: 150px;
    border-top-left-radius: 70px;
    border-bottom-left-radius: 250px;
  }

  .stepcircle.step6 {
    top: 13%;
  }

  .stepcircle.step3 {
    top: 13%;
  }

  .stepcircle.step7 {
    bottom: 15%;
  }

  .statusta6 {
    left: -28px;
    top: 53px;
    width: 60px;
    height: 100px;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 40px;
    line-height: 48px;
  }

  h2 {
    font-size: 32px;
    line-height: 40px;
  }

  h3 {
    font-size: 28px;
    line-height: 36px;
  }

  h4 {
    font-size: 24px;
    line-height: 32px;
  }

  h5 {
    font-size: 20px;
    line-height: 32px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  .test {
    margin-top: 5vw;
    margin-right: 5vw;
  }

  .source-info {
    width: 100%;
  }

  .navbar {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .navbar-link {
    margin-right: 24px;
  }

  .color-tiles {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .color-tile {
    height: 120px;
  }

  .navbar-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .container.small {
    width: 90%;
  }

  .field-label {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .select-way-lang {
    height: 40px;
  }

  .navbar-link-2 {
    margin-right: 24px;
  }

  .dashboard-table.relative {
    width: 460vw;
  }

  .heading {
    font-size: 1.8em;
  }

  .column {
    padding-right: 0px;
    padding-left: 0px;
  }

  .container-2 {
    width: 80%;
  }

  .trackingmod {
    min-width: 550px;
    margin-left: 1%;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 40px;
    line-height: 48px;
  }

  h2 {
    font-size: 32px;
    line-height: 40px;
  }

  h3 {
    font-size: 28px;
    line-height: 36px;
  }

  h4 {
    font-size: 24px;
    line-height: 32px;
  }

  h5 {
    font-size: 20px;
    line-height: 32px;
  }

  h6 {
    font-size: 16px;
    line-height: 24px;
  }

  p {
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 24px;
  }

  .class {
    margin: 24px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .test {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 8.533vw;
    z-index: 99;
    display: block;
    width: 17.067vw;
    height: 17.067vw;
    margin-top: auto;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .nav-toggle-wrap {
    z-index: 99;
    display: block;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .source-info {
    width: 100%;
    padding: 16px 0px 0px;
  }

  .clone-button {
    width: 100%;
    min-height: 32px;
    text-align: center;
  }

  .stylesection {
    margin-top: 32px;
  }

  .typography {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .navbar {
    position: fixed;
    left: 0px;
    top: auto;
    right: 0px;
    bottom: 0px;
    padding: 12px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-radius: 0px;
    background-color: rgba(32, 32, 32, 0.95);
  }

  .navbar-link {
    margin-right: 4px;
    margin-left: 4px;
  }

  .color-tiles {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }

  .color-tile {
    height: 80px;
  }

  .style-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: start;
    -ms-flex-line-pack: start;
    align-content: start;
    -ms-grid-columns: 0.5fr;
    grid-template-columns: 0.5fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .header {
    padding-top: 40px;
    padding-bottom: 32px;
  }

  .header-inner {
    text-align: left;
  }

  .forms {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .laptop-div {
    width: 90%;
  }

  .navbar-link-2 {
    margin-right: 4px;
    margin-left: 4px;
  }

  .faq-answer {
    padding-right: 0px;
  }

  .faq-answer.box {
    display: none;
    padding-right: 0px;
  }

  .faq-element.box {
    padding-right: 24px;
    padding-left: 24px;
  }

  .dashboard-table.relative {
    width: 690vw;
  }

  .heading {
    font-size: 1.5em;
  }

  .container-2 {
    width: 95%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

#w-node-_33f7eed1-2e52-16e5-361f-c315c9f564df-24217889 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0f4adf76-84f5-5740-62e7-c4df7d84c971-24217889 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_547ceac7-18ac-a783-4292-6b4dab5cd59f-24217889 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_8e5fd6d9-111a-3012-47fb-0037dd4b5418-24217889 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_3c9e5a41-5527-6926-d7ee-c98c03f82f08-24217889 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_79b9fa4c-57d8-e774-1e54-445145a3d4ef-24217889 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_8543ee48-d200-1fd3-d0fd-d6565778a0f5-24217889 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_64ecd990-666a-ca33-efd9-bed8573d3645-24217889 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
}

#w-node-_239adc92-dc04-729d-3047-e18a36bb8c4c-24217889 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c4176fcb-acd4-4f72-298a-dfa7867e1f13-24217889 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_1627909d-9c99-b786-73d6-c0eacf63e38c-24217889 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-b26b9db1-2b96-275d-eac0-28bf799f95ea-67433819 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_8142211a-2317-2ad6-5c67-30d26c162b6a-67433819 {
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-c83226ac-4fd3-7d5b-73a8-63f77d8cadfb-67433819 {
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-d89b6086-4a1c-3ef9-7be0-924834c3d9fe-93a0db08 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_8142211a-2317-2ad6-5c67-30d26c162b6a-8999ae95 {
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

#w-node-_8142211a-2317-2ad6-5c67-30d26c162b6a-e4147b85 {
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

#w-node-e4064445-d78f-233b-4547-fd3dfe1c6dbf-005b04f4 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_8142211a-2317-2ad6-5c67-30d26c162b6a-191de5b3 {
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

#w-node-_8142211a-2317-2ad6-5c67-30d26c162b6a-ec42405d {
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

#w-node-_8142211a-2317-2ad6-5c67-30d26c162b6a-0d19368e {
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

#w-node-_8142211a-2317-2ad6-5c67-30d26c162b6a-8bf43e11 {
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

/* @font-face {
  font-family: 'Icomoon';
  src: url('../fonts/icomoon.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cera pro';
  src: url('../fonts/Cera-Pro-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cera pro';
  src: url('../fonts/Cera-Pro-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cera pro';
  src: url('../fonts/Cera-Pro-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cera pro';
  src: url('../fonts/Cera-Pro-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
} */
body {
    font-family: 'Cera pro', sans-serif;
}

.container-fluid .main-content .header-navbar, .container-fluid .main-content>.main-navbar {
    box-shadow: none;
}

.container-fluid .main-navbar .navbar .nav-link {
    display: flex;
}
a.text-nowrap.px-3.dropdown-toggle.nav-link:hover  {
    text-decoration: none;
}

.program_selector {
    margin: 1rem 1rem;
}

.program_selector label {
    font-weight: bold;
    margin-top: 1rem;
    color: #0c2652;
}

.program_selector label:first-child {
    margin-top: 0;
}

#main-logo {
    width: 150px;
    height: 48px;
}
.navlogo {
    width: 0;
    height: 0;
}

.main-navbar:hover a {
    border: 0;
}

.container-fluid .main-sidebar {
    top: 15px;
    border-top-right-radius: 40px;
}

.container-fluid .main-sidebar .main-navbar {
    margin-top: 35px;
    background-color: white;
    margin-bottom: 50px;
}

.container-fluid .navbar-nav {
    background-color: #e4f0fb;
    border-bottom-left-radius: 30px;
}

.container-fluid .nav-item.dropdown {
    padding: 0 1.5rem;
}

.container-fluid .navbar {
    padding: 0;
}

.main-sidebar .nav .nav-item .nav-link span {
    color: #6d6d6d;
    font-size: 1.125em;
    font-family: 'Cera pro', sans-serif;
}

.quick-links-inner-div .paragraph {
    text-align: left;
}

.graph-div a:hover {
    text-decoration: none;
}

.graph-div.last-div {
    margin-top: 50px;
}

.box .plus-vertical {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    -webkit-transition: -webkit-transform 1s;
    -moz-transition: -moz-transform 1s;
    -o-transition: -o-transform 1s;
    -ms-transition: -ms-transform 1s;
    transition: transform .5s;
}
.box.open .plus-vertical {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-90deg) skew(0deg, 0deg);
}
body .h1, body .h2, body .h3, body .h4, body .h5, body .h6, body h1, body h2, body h3, body h4, body h5, body h6 {
    font-family: 'Cera pro', sans-serif;
}
.modal-dialog .modal-content {
    font-family: 'Cera pro', sans-serif;
    border-radius: 1.25rem;
}
.modal-content label {
    font-weight: bold;
}
.modal .modal-title h4, .form-heading {
    font-size: 24px;
}
.modal .modal-header .btn-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    padding: 0;
    margin: 0;
    background-image: none;
    height: 25px;
    width: 25px;
}

.modal .modal-header .btn-close:before {
    content: "\e954";
    font-family: "icomoon";
    font-size: 25px;
    color: black;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 1em;
}

.modal .transparent-inner-icon .icomoon {
    padding-right: 0.5em;
}
.modal .modal-header {
    border-bottom: none;
    padding-bottom: 0;
    background-color: #f0f6ff;
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
}
.modal .modal-footer {
    border-top: none;
}
.modal-title.h4 {
    font-family: 'Cera pro', sans-serif;
}

@media (min-width: 576px) {
        .modal .modal-dialog {
            min-width: 800px;
        }
}

.request-packaging-shipping-label {
    margin-top: 0;
}

#btn_add {
    align-self: center;
}

#chart-T {
    display: block; width: 294px; height: 147px;
}

#chart-RR {
    display: block; width: 294px; height: 147px;

}

table.table td {
    font-size: 13px;
    color: #6d6d6d;
}
table.table tr, .table>:not(caption)>*>* {
    border: none;
}
table.table tr:nth-child(even) {
    background-color: #f2f2f2;
}

table.table>:not(:first-child) {
    border: none;
}

.main-content .main-navbar {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1050;
}

.page-body {
    background-color: #f0f6ff;
    margin-top: 2rem;
    margin-bottom: 200px;
}

.page-body .container-fluid > .row {
    align-items: flex-start;
}

.content > .row {
    margin-bottom: 2rem;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: 'Cera pro', sans-serif;
}

#root .chart {
    height: 150px;
    width: 150px;
}

.graph-div h4 {
    font-family: 'Cera pro', sans-serif;
}

.loqate-dropdowns.input-field.w-input {
    display: flex;
    align-items: center;
}

.loqate-dropdowns .form-control {
    padding: 0;
    border: 0;
    font-size: inherit;
    font-weight: inherit;
    box-shadow: none;
}

.loqate-dropdowns .form-control:focus {
    box-shadow: none;
}

.modal-dialog .loqate-dropdowns ul {
    top: 56px;
    left: 0;
    z-index: 2000;
    overflow: visible;
    padding: 0 20px;
    font-weight: normal;
}

.faq-element.box .faq-answer.box {
    max-height: 0;
    transition: max-height .5s;
    overflow: hidden;
}

.faq-element.box.open .faq-answer.box {
    max-height: 500px;
    transition: max-height 1s;
}

select {
    /* turn off shadow on Safari */
    -webkit-appearance:none;
}

.mapped_devices {
    width: 100%;
    margin: 1rem 0 3rem 0;
}
.mapped_devices th {
    border-bottom: 1px solid #cccccc;
}

.mapped_devices td.quantity {
    text-align: right;
    padding-right: 3rem;
}

.mapped_devices th.quantity {
    width: 10rem;
}

.mapped_devices th {
    text-align: center;
}

.mapped_devices th:first-child {
    text-align: left;
}
.mapped_devices td {
    line-height: 1.5rem;
}

.mapped_devices tr:first-child:hover {
    background-color: transparent;
}

.mapped_devices tr:hover {
    background-color: #f0f6ff;
}
.mapped_devices th:last-child {
    width: 50px;
}
.mapped_devices td:last-child {
    cursor: pointer;
}

.form-heading-div + p {
    margin-top: -1rem;
    font-weight: normal;
}

#audit .table-responsive, #tracking .table-responsive {
    max-height: 1000px;
}
.modal .react-datepicker-wrapper, 
.modal .react-datepicker-wrapper .react-datepicker__input-container,
.modal .react-datepicker-wrapper .form-control {
    display: block;
}

.react-datepicker-wrapper .form-control {
    border: 1px solid #b3b3b3;
    border-radius: 20px;
    color: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-size: inherit;
}
.react-datepicker-wrapper .form-control.input-field {
    padding: 0 20px;
}

.pagination .page-item {
    margin-right: 10px;
    border-radius: 4px;
}

.pagination .page-item .page-link {
    padding: 5px 10px;
    line-height: 14px;
    font-size: 14px;
    border-radius: 4px;
    font-family: 'Cera pro', sans-serif;
    color: #666;
    font-weight: 200;
}

.page-item:first-child .page-link, .page-item:last-child .page-link {
    border-radius: 8px;
}

.pagination .page-item.disabled .page-link, .pagination .page-item.active .page-link {
    border-color: #666;
    background-color: #666;
    cursor: default;
    color: white;
}

.container-fluid .main-sidebar {
    z-index: 500;
}

.container-fluid .main-sidebar .nav-wrapper {
  height: auto;
}

.program_info > div {
  font-size: 1rem;
  margin-bottom: .5rem;
}

.program_info {
  margin: 40px 1.5rem 0 1.5rem;
  padding: 20px 10px;
  border-radius: 4px;border: 1px dotted #cecece;
  box-shadow: inset 3px 3px 5px rgba(0,0,0,0.2);
}

.program_info span {
  font-weight: bold;
  display: block;
}

.program_info button {
  padding-top: 5px;
  padding-bottom: 6px;
  font-size: 0.85rem;
  box-shadow: 5px 5px 7px rgba(0,0,0,0.3);
}

@media (min-width: 576px) {
  .program_modal .modal-dialog {
    max-width: 480px;
    min-width: 0;
  }  
}

.moblyz_table .filter_table {
  margin-top: 1rem;
}

.moblyz_table_filters {
  display: flex;
  justify-content: space-between;
}
.moblyz_table .applied_filters div {
  border: 2px solid #cccccc;
  border-radius: 25px;
  padding-left: 12px;
  display: inline-block;
  background-color: white;
  margin-right: 8px;
}
.moblyz_table .applied_filters {
  margin-top: 1rem;
}

.moblyz_table .table_info {
  display: flex;
}

.moblyz_table .table_info div {
  margin-left: 0.5rem;
}

.moblyz_table .table_info div p {
  padding-top: 7px;
}

.moblyz_table .applied_filters div button {
  background-color: transparent;
}

.rettracknumtxt, .outtracknumtxt {
  margin-top: 0;
}